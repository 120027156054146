.modal {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 5;
    background-color: var(--black);
    overflow-y: auto;
}

.modal__title {
    position: relative;
}

.modal__arrow {
    max-width: 45px;
    position: absolute;
    left: 0%;
    margin-top: 5px;
    cursor: pointer;
}

.modal__content div:first-child {
    grid-column: span 3;
}

.modal__img {
    max-width: 100%;
    object-fit: contain;
    border-top-right-radius: 2%;
    border-top-left-radius: 2%;
}

.modal__extra svg {
    max-width: 40px;
}

.modal h3 {
    padding: 20px;
    border-bottom: 4px solid var(--secondary);
    margin-top: 0;
    margin-bottom: 0;
}

.modal__links {
    padding: 5px;
    margin: 10px;
    background-color: var(--darkgrey);
}

.modal__links a {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.modal__links svg, .modal__links img {
    max-width: 40px;
    align-self: center;
    justify-self: center;
}

.modal__links a {
    align-self: center;
    text-align: left;
}

.modal__list, .modal__links {
    padding: 5px;
    margin: 0px 10px;
    margin-bottom: 10px;
    border-radius: 3%;
}

.modal__list ul li {
    border-bottom: none;
}

.modal__summary {
    text-align: left;
    background-color: var(--darkgrey);
    border-bottom-left-radius: 3%;
    border-bottom-right-radius: 3%;
    padding: 5px;
}

.modal__summary p {
    padding: 0px 20px;
}