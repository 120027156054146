.project {
    padding: 10px;
    cursor: pointer;
}

.project:hover {
    transform: scale(1.03);
}

.project__img {
    border-top-right-radius: 2%;
    border-top-left-radius: 2%;
}

.project__content {
    height: 280px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--darkgrey);
    border-bottom-right-radius: 2%;
    border-bottom-left-radius: 2%;
}

.project__content h3 {
    margin: 0;
    padding: 10px 0;
    text-align: left;
    border-bottom: 2px solid var(--secondary);
}

.project__content p {
    font-size: 20px;
    text-align: left;
    margin: 5px 0px;
}

.project__content svg {
    max-width: 20px;
}

.projects__content__tools .sqlfill {
    fill: var(--black) !important;
}

.projects__content__tools ul {
    display: flex;
    grid-gap: 15px;
    padding: 0;
    margin: 5px 0px;
}

.projects__content__tools ul li {
    display: flex;
    grid-gap: 5px;
}

.projects__content__tools ul li p {
    font-size: 16px;
    margin: 0;
}

.project-highlight-vertical1 {
    grid-row: 1 / span 2;
    grid-column: 3;
}

.project-highlight-vertical2 {
    grid-row: 3 / span 2;
    grid-column: 3;
}

.project-highlight-normal {
    grid-row: auto / span 2;
}

.project-highlight-large {
    grid-column: auto / span 2;
    grid-row: auto / span 3;
}

.project-highlight-large2 {
    grid-column: auto / span 2;
    grid-row: 4 / span 3;
}

.project-highlight-large2 .project__content, .project-highlight-large .project__content {
    height: 300px;
}
