.contact {
    background-color: var(--darkgrey);
    padding-top: 50px;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 40px;
}

button {
    max-width: 70px;
    background-color: var(--grey);
    border-width: 0;
    padding: 15px;
    margin: 15px;
    cursor: pointer;
}

button:hover {
    background-color: var(--black);
}

button svg, button svg path {
    fill: var(--lightsecondary)
}

.button__text {
    max-width: 250px;
    color: var(--lightsecondary);
}

.button__text p {
    font-size: 20px;
    margin: 0;
}