.about {
    padding-top: 40px;
}

.about__content {
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 20px;
}

.about__content h2 {
    text-align: left;
}

.about__content > * span {
    color: var(--lightsecondary)
}

.about__img {
    border-radius: 1%;
}

.about .two-grid div:first-child {
    grid-column: 1;
}

.about__img-wrapper {
    padding-right: 20px;
}

/* 
    background-image: url('../images/suit.jpg');
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
@media only screen and (max-device-width: 1366px) {
  .parallax {
    background-attachment: scroll;
  }
} */