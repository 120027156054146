footer {
    background-color: var(--darkgrey);
    padding: 10px;
}

footer p, footer p > * {
    font-size: 20px;
    color: var(--secondary);
}

footer p a:hover {
    color: var(--white);
}

footer p:last-child {
    font-size: 18px;
}