.two-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.three-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.four-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}