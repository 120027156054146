:root {
    --black: #010101;
    --darkgrey: #1E1E1E;
    --grey: #363636;
    /* --darkgrey: #363636;
    --grey: #646464; */
    --lightsecondary: #F1A540;
    --secondary: #646464;
    --white: #FEFFFE;
}

* {
    box-sizing: border-box;
}

body,
html {
    margin: 0;
    min-height: 100vh;
    background-color: var(--black);
    color: var(--white);
    text-align: center;
    scroll-behavior: smooth;
    overflow-x: clip;
}

img {
    max-width: 100%;
}

svg {
    width: 100%;
    height: auto;
}

.container {
    max-width: 1440px;
    margin: auto;
}

.wrapper {
    padding: 50px 40px;
}

li {
    list-style-type: none;
}

li svg path:first-child {
    fill: var(--lightsecondary);
}