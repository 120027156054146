.primary-nav {
    background-color: var(--darkgrey);
    border-bottom: 2px solid var(--lightsecondary);
}

.primary-nav div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
}

.primary-nav div nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    column-gap: 40px;
}
.primary-nav div nav ul li a {
    font-size: 18px;
    color: var(--white);
}

.primary-nav div nav ul li a:hover {
    color: var(--secondary);
}

.logo span {
    color: var(--lightsecondary);
}

.logo {
    font-family: 'Questrial', sans-serif;
    font-size: 40px;
    color: var(--white);
    padding: 5px 0px;
}

.logo:hover {
    color: var(--white);
}

.sticky-header {
    top: 0;
    position: sticky;
    z-index: 3;
}